import { PrismicImage, PrismicRichText, PrismicPageDefinition } from "./types";

export type TypeResourceItemData = {
  image: PrismicImage;
  background_color: string;
  download_text: PrismicRichText;
  overlay_text: PrismicRichText;
  overlay_text_color: string;
};

export type TypeBrandPageData = {
  title: string;
  meta_title: string;
  subtitle: string;
  hero_image: PrismicImage;

  page_meta_description: string;
  page_meta_thumbnail: PrismicImage;
  resources_1_intro: PrismicRichText;
  column_layout: number;
  resources_1_items: Array<TypeResourceItemData>;

  resources_2_intro: PrismicRichText;
  column_layout_2: number;
  resources_2_items: Array<TypeResourceItemData>;

  resources_3_intro: PrismicRichText;
  column_layout_3: number;
  resources_3_items: Array<TypeResourceItemData>;

  resources_4_intro: PrismicRichText;
  column_layout_4: number;
  resources_4_items: Array<TypeResourceItemData>;
  resources_4_outro: PrismicRichText;
};

export type TypeBrandPageQueryResult = {
  allPrismicBrandPage: {
    edges: Array<{
      node: { data: TypeBrandPageData };
    }>;
  };
};

export const getPage = (data: TypeBrandPageQueryResult): TypeBrandPageData => {
  return data.allPrismicBrandPage.edges[0].node.data;
};
