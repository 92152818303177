import * as React from "react";
import {
  PageHeaderCustomV2Grey,
  PageHeaderStandard,
} from "../../components/page-header/page-header";
import { graphql, StaticQuery } from "gatsby";
import {
  getPage,
  TypeBrandPageData,
  TypeBrandPageQueryResult,
} from "../../utils/queries/brand_page";
import clsx from "clsx";

import { PageTitle } from "../../components/title/title";
import AmazeeIoMetadata from "../../components/metadata";
import AmazeePage from "../../components/layout";
import "./our-brand.scss";

import RichTextRender from "../../utils/rich-text";
import usePreviewData from "../../utils/usePreviewData";

const BrandPage = ({
  data,
  location,
}: {
  location: Location;
  data: TypeBrandPageQueryResult;
}) => {
  const pageData = getPage(data);
  if (!pageData) return null;
  const result = usePreviewData(
    pageData,
    "prismicBrandPage",
    true
  ) as TypeBrandPageData;

  const title = result.meta_title ? result.meta_title : result.title;

  return (
    <AmazeePage location={location}>
      <div id="our-brand">
        <PageTitle title={title} amazeeOnly={true} />
        <PageHeaderStandard
          component={PageHeaderCustomV2Grey}
          header={result.title}
          image={result.hero_image}
          subheader={result.subtitle}
          withSiteName={false}
          hasSectionDivider={false}
        />

        <AmazeeIoMetadata
          title={result.title}
          page_meta_description={result.page_meta_description}
          page_meta_thumbnail={result.page_meta_thumbnail}
          location={location}
        />

        <div className="container">
          <div className="row flex justify-center">
            <div className="col-12 col-lg-10 px-0">
              <section id="resources-1">
                {RichTextRender(result.resources_1_intro)}

                {result.resources_1_items.length > 0 ? (
                  <div
                    className={clsx(
                      `grid gap-7 md:grid-cols-${result.column_layout}`
                    )}
                  >
                    {result.resources_1_items.map((resource) => {
                      return (
                        <div>
                          <div
                            className="resource-item-image-wrapper has-border"
                            style={{
                              backgroundColor: resource.background_color,
                            }}
                          >
                            <div
                              style={{
                                backgroundImage: `url('${resource.image.url}')`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "auto",
                                backgroundPosition: "center center",
                                height: "18.75rem",
                                margin: "0 auto",
                                width: "100%",
                              }}
                            ></div>
                          </div>
                          <div className="resource-download-links">
                            {RichTextRender(resource.download_text)}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </section>

              <section id="resources-2">
                {RichTextRender(result.resources_2_intro)}

                {result.resources_2_items.length > 0 ? (
                  <div
                    className={clsx(
                      `grid gap-7 md:grid-cols-${result.column_layout_2}`
                    )}
                  >
                    {result.resources_2_items.map((resource) => {
                      return (
                        <div>
                          <div
                            className="resource-item-image-wrapper has-border"
                            style={{
                              backgroundColor: resource.background_color,
                            }}
                          >
                            <div
                              style={{
                                backgroundImage: `url('${resource.image.url}')`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "auto",
                                backgroundPosition: "center center",
                                height: "18.75rem",
                                margin: "0 auto",
                                width: "100%",
                              }}
                            ></div>
                          </div>
                          <div className="resource-download-links">
                            {RichTextRender(resource.download_text)}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </section>

              <section id="resources-3">
                {RichTextRender(result.resources_3_intro)}

                {result.resources_3_items.length > 0 ? (
                  <div
                    className={clsx(
                      `grid gap-7 md:grid-cols-${result.column_layout_3}`
                    )}
                  >
                    {result.resources_3_items.map((resource) => {
                      return (
                        <div>
                          <div
                            className="resource-item-image-wrapper has-border"
                            style={{
                              backgroundColor: resource.background_color,
                            }}
                          >
                            <div
                              style={{
                                backgroundImage: `url('${resource.image.url}')`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "auto",
                                backgroundPosition: "center center",
                                height: "18.75rem",
                                margin: "0 auto",
                                width: "100%",
                              }}
                            ></div>
                          </div>
                          <div className="resource-download-links">
                            {RichTextRender(resource.download_text)}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </section>

              <section id="resources-4">
                {RichTextRender(result.resources_4_intro)}

                {result.resources_4_items.length > 0 ? (
                  <div
                    className={clsx(
                      `grid gap-7 md:grid-cols-${result.column_layout_4}`
                    )}
                  >
                    {result.resources_4_items.map((resource) => {
                      return (
                        <div>
                          <div
                            className={clsx(
                              "relative resource-item-image-wrapper",
                              resource.background_color === "#ffffff"
                                ? "has-border"
                                : ""
                            )}
                            style={{
                              backgroundColor: resource.background_color,
                            }}
                          >
                            <div
                              style={{
                                height: "12.5rem",
                                maxWidth: "23.125rem",
                              }}
                            ></div>
                            <div
                              className="absolute bottom-5 left-7 overlay-text"
                              style={{ color: resource.overlay_text_color }}
                            >
                              {RichTextRender(resource.overlay_text)}
                            </div>
                          </div>
                          <div className="resource-download-links">
                            {RichTextRender(resource.download_text)}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : null}

                <div className="outro">
                  {RichTextRender(result.resources_4_outro)}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </AmazeePage>
  );
};

export default ({ location }: { location: Location }) => {
  const query = graphql`
    {
      allPrismicBrandPage(limit: 1) {
        edges {
          node {
            data {
              hero_image {
                alt
                url
              }
              subtitle
              title
              page_meta_description
              page_meta_thumbnail {
                alt
                copyright
                url
                thumbnails
              }
              column_layout
              column_layout_2
              column_layout_3
              column_layout_4
              resources_1_intro {
                ...PrismicStructuredTextTypeFragment
              }
              resources_2_intro {
                ...PrismicStructuredTextTypeFragment
              }
              resources_3_intro {
                ...PrismicStructuredTextTypeFragment
              }
              resources_4_intro {
                ...PrismicStructuredTextTypeFragment
              }
              resources_4_outro {
                ...PrismicStructuredTextTypeFragment
              }
              resources_1_items {
                background_color
                download_text {
                  ...PrismicStructuredTextTypeFragment
                }
                image {
                  url
                  alt
                }
                overlay_text {
                  ...PrismicStructuredTextTypeFragment
                }
                overlay_text_color
              }
              resources_2_items {
                background_color
                download_text {
                  ...PrismicStructuredTextTypeFragment
                }
                image {
                  url
                  alt
                }
                overlay_text {
                  ...PrismicStructuredTextTypeFragment
                }
                overlay_text_color
              }
              resources_3_items {
                background_color
                download_text {
                  ...PrismicStructuredTextTypeFragment
                }
                image {
                  url
                  alt
                }
                overlay_text {
                  ...PrismicStructuredTextTypeFragment
                }
                overlay_text_color
              }
              resources_4_items {
                background_color
                download_text {
                  ...PrismicStructuredTextTypeFragment
                }
                image {
                  url
                  alt
                }
                overlay_text {
                  ...PrismicStructuredTextTypeFragment
                }
                overlay_text_color
              }
            }
          }
        }
      }
    }

    fragment PrismicStructuredTextTypeFragment on PrismicStructuredTextType {
      html
      raw
      text
    }
  `;
  return (
    <StaticQuery
      query={query}
      render={(data) => <BrandPage location={location} data={data} />}
    />
  );
};
